<template>
    <div>
        <div class="boxData">
            <div class="brief">完善个人资料是让别人认识你的第一步</div>
            <h4>基本信息</h4>
            <div class="userNickname">
                <p><span>*</span>昵称</p> <input :class="userNickname?'':'active'" type="text" v-model="userNickname"><span style="fontSize:12px;color:#df2b2b;margin-left:5px" v-if="!userNickname">昵称必填</span>
            </div>
            <div class="userPic">
                <span>头像</span><img :src="headPic?headPic:'../../assets/img/userpic.png'" alt=""><input type="file" accept="image/*" @change="userPicClick">
            </div>
            <div class="userSex">
                <span>性别</span>
                <div class="div"> 
                    <input type="radio" id="boy" value="男" v-model="picked"><label for="boy">男</label>
                    <input type="radio" id="girl" value="女" v-model="picked"><label for="girl">女</label>
                    <input type="radio" id="secrecy" value="保密" v-model="picked"><label for="secrecy">保密</label>
                </div>
            </div>
            <!-- <div class="userInfor">
                <span>个人介绍</span>
                <textarea v-model="message" placeholder="用一段话介绍你自己，会在你的个人页面显示，最多输入80字"></textarea>
            </div> -->
            <h4>联系信息</h4>
            <div class="userName">
                <span>真实姓名</span> <input type="text" v-model="userName">
            </div>
            <div class="userPhone">
                <div class="phoneTop">
                    <span>手机号</span> <input type="text" v-model="userPhone">
                </div>
                <div class="textSmall">用于优质课程的学习提醒等服务，手机号不会对外公开，请放心</div>
            </div>
            <div class="userQq">
                <span>QQ号码</span> <input type="text" v-model="userQq">
            </div>
            <div class="userWeiXin">
                <span>微信号码</span> <input type="text" v-model="userWeixin">
            </div>
            <div class="Userbtn">
                <div class="formBtn" @click="confim">保存</div>
            </div>
        </div>
        <div class="successFlex" v-if="showCon">
            <div class="msg">提示</div>
            <div class="preseSuccess">保存成功!</div>
            <div class="botBtn">确定</div>
        </div>
        <div class="fixx" v-if="successFlag">
            <div class="msgg">提示</div>
            <div class="succ">保存成功!</div>
            <div class="succBtn" @click="clickSuccess">确定</div>
        </div>
    </div>
</template>

<script>
import { getUserByPhone,updateAppUser,uploadPic } from '@/common/public.js'
export default {
    data(){
        return{
            userObj:{},
            showCon:false,
            successFlag:false,//提示框
            picked:'',//性别选择
            userNickname:'',//昵称
            message:'',//多行文本
            userName:'',//真实姓名
            userPhone:'',//手机号
            userQq:'',//QQ号码
            userWeixin:'',//微信号码
            headPic:'',//用户头像
        }
    },
    methods:{
        //点击确定隐藏提示框
        clickSuccess(){
            this.successFlag = false;
        },
        //上传头像信息
        userPicClick(e){
            let file = e.target.files[0];
            let formData =new FormData(); 
            //参数以就是后台定义的字段名
            formData.append("file",file);
            uploadPic(formData).then(res=>{
                this.headPic = res.result.url;
            })
        },
        //点击保存提交信息
        confim(){
            if(!this.userNickname){
                this.$message({
                    showClose: true,
                    message: '请输入您的昵称',
                    type: 'error'
                });
            }else if(!this.userPhone){
                this.$message({
                    showClose: true,
                    message: '请输入您的手机号',
                    type: 'error'
                });
            }else if(!this.headPic){
                this.$message({
                    showClose: true,
                    message: '请上传头像',
                    type: 'error'
                });
            }else{
                this.getUpdateAppUser()
            }
        },
        //修改用户信息接口
        getUpdateAppUser(){
            let userId = this.$store.state.userid;//用户id
            let token = this.$store.state.token;//用户token
            let nickname = this.userNickname;//用户昵称
            let headPic = this.headPic;//用户头像
            let sex = this.picked;//用户性别
            let realName = this.userName;//用户真实姓名
            let qqCode = this.userQq;//用户qq
            let wechatCode = this.userWeixin;//用户微信
            let idCard = '';//身份证号
            let email = '';//邮箱
            let birthday = '';//生日
            let data = {
                userId,token,nickname,headPic,sex,realName,qqCode,wechatCode,idCard,email,birthday
            };
            updateAppUser(data).then(res=>{
                if(res.code == 200){
                    this.successFlag = true;
                    this.$store.commit('replaceUserInfor',res.result.domain)
                    this.getUserInfor()
                }else{
                    this.$message({
                        showClose: true,
                        message: '保存失败',
                        type: 'error'
                    });
                }
            })
        },
        //获取用户信息
        getUserInfor(){
            let data = {
                userId:this.$store.state.userid,
                token:this.$store.state.token,
                phone:this.$store.state.user.phone,
            };
            getUserByPhone(data).then(res=>{
                this.userObj = res.result.domain;
                this.userPhone = res.result.domain.phone;
                this.userNickname = res.result.domain.nickname;
                this.picked = res.result.domain.sex;
                this.userQq = res.result.domain.qqCode;
                this.userWeixin = res.result.domain.wechatCode;
                this.userName = res.result.domain.realName;
                this.headPic = res.result.domain.headPic;
            })
        }
    },
    mounted(){
        //一进页面调用接口加载用户信息
        this.getUserInfor()
    }
}
</script>

<style scoped lang="less">
.successFlex{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 320px;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .msg{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }
    .preseSuccess{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        margin-top: 8px;
    }
    .botBtn{
        width: 108px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #FF8000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
        margin-top: 32px;
        cursor: pointer;
    }
}
.boxData{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .brief{
        font-size: 12px;
        font-weight: 400;
        line-height: 43px;
        color: #666666;
        margin-bottom: 5px;
    }
    h4{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #333333;
        margin: 13px 0;
    }
    .userNickname{
        width: 100%;
        padding-left: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 13px 0;
        p{
            margin-right: 10px;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            span{
                color: #CD0C0C;
                margin-right: 3px;
            }
        }
        input{
            width: 160px;
            height: 32px;
            background: #FAFAFA;
            border: 1px solid #DFDFDF;
            outline: none;
            padding-left: 6px;
        }
        .active{
            border: 1px solid #DF2B2B;
        }
    }
    .userPic{
        margin: 13px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
        position: relative;
        span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 10px;
        }
        img{
            width: 69px;
            height: 68px; 
            border-radius: 50%;
        }
        input{
            position: absolute;
            width: 69px;
            height: 68px;
            background: pink;
            outline: none;
            left: 76px;
            top: 0;
            opacity: 0;
        }
    }
    .userSex{
        margin: 13px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
        span{
            margin-right: 10px;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
        }
        .div{
            width: 150px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            input[type="radio"] + label::before {
                content: "\a0"; /*不换行空格*/
                display: inline-block;
                vertical-align: middle;
                font-size: 18px;
                width: 10px;
                height: 10px;
                margin-right: 3px;
                border-radius: 50%;
                border: 1px solid #ccc;
                text-indent: .15em;
                line-height: 1; 
            }
            input[type="radio"]:checked + label::before {
                background-color: #01cd78;
                background-clip: content-box;
                border: 1px solid #097638;
                padding: 2px;
            }
            input[type="radio"] {
                position: absolute;
                clip: rect(0, 0, 0, 0);
            }
        }
    }
    .userInfor{
        padding-left: 40px;
        margin: 13px 0;
        display: flex;
        flex-direction: row;
        span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 10px;
        }
        textarea{
            width: 374px;
            height: 103px;
            padding: 10px 0 10px 4px;
            text-indent: 1rem;
            background: #FAFAFA;
            border: 1px solid #DFDFDF;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #999999;
            outline: none;
        }
    }
    .userName{
        margin: 13px 0;
        padding-left: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 10px;
        }
        input{
            width: 160px;
            height: 32px;
            background: #FAFAFA;
            border: 1px solid #DFDFDF;
            outline: none;
            padding-left: 5px;
        }
    }
    .userPhone{
        margin: 13px 0;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        .phoneTop{
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
                width: 50px;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #333333;
                margin-right: 10px;
                text-align: right;
            }
            input{
                width: 160px;
                height: 32px;
                background: #FAFAFA;
                border: 1px solid #DFDFDF;
                outline: none;
                padding-left: 5px;
            }
        }
        .textSmall{
            margin-top: 15px;
            width: 100%;
            padding-left: 60px;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #999999;
        }
    }
    .userQq{
        margin: 13px 0;
        padding-left: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 14px;
        }
        input{
            width: 160px;
            height: 32px;
            background: #FAFAFA;
            border: 1px solid #DFDFDF;
            outline: none;
            padding-left: 5px;
        }
    }
    .userWeiXin{
        margin: 13px 0;
        padding-left: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 10px;
        }
        input{
            width: 160px;
            height: 32px;
            background: #FAFAFA;
            border: 1px solid #DFDFDF;
            outline: none;
            padding-left: 5px;
        }
    }
    .Userbtn{
        padding-left: 66px;
        margin: 13px 0 30px 0;
        .formBtn{
            width: 200px;
            height: 44px;
            background: #FF8000;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #FFFFFF;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    
}
.fixx{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 320px;
        height: 160px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 23px 0 21px;
        .msgg{
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: #000000;
        }
        .succ{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #000000;
            margin-top: 4px;
        }
        .succBtn{
            width: 108px;
            height: 32px;
            margin-top:32px;
            background: #FF8000;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #FFFFFF;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
</style>