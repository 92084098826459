<template>
  <div class="box">
    <div class="title"><span>课程浏览记录</span></div>
    <div class="list">
      <div class="listBox" v-if="arr.length > 0">
        <div class="item" v-for="(item, index) in arr" :key="index" @click="toDetails(item.videoCourseId)">
          <div class="img">
            <img :src="item.coverUrl" alt="" />
          </div>
          <div class="bot">
            <p class="tit">{{item.name}}</p>
            <p class="time">{{item.time | toTime(item.time)}}</p>
          </div>
        </div>
      </div>
      <div class="npHostory" v-else>
          <div class="cont">
              <img src="@/assets/img/noContent.png" alt="" />
              <p>暂无记录!</p>
              <span>快去查看课程吧</span>
          </div>
      </div>
      <paging v-if="arr.length > 0" :total="total" :rows="rows" @clickPage="pageList"></paging>
    </div>
  </div>
</template>

<script>
import { getPageViews } from '@/common/public.js'
import paging from '@/components/course/paging.vue'
export default {
  components:{
    paging
  },
  data() {
    return {
      arr: [],//浏览数据
      page:1,
      rows:16,
      total:300,//总条数
    };
  },
  created(){
    this.getPage()
  },
  methods:{
    //点击跳转到播放页面
    toDetails(id){
      this.$router.push({
        path:'/playdetails',
        query:{
          id
        }
      })
    },
    //点击切换下一页、
    pageList(index){
      this.page = index;
      this.getPage()
    },
    //浏览记录
    getPage(){
      let data = {
        userId:this.$store.state.userid,
        page:this.page,
        rows:this.rows
      };
      getPageViews(data).then(res=>{
        this.arr = res.result.mapList;
        this.total = res.result.listSize;
      })
    }
  }
};
</script>

<style scoped lang="less">
.box {
  width: 960px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #007131;
    border-bottom: 1px solid #eeeeee;
    padding: 11px 0;
    span {
      padding: 8px 0;
      border-bottom: 4px solid #fed23e;
    }
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
    .npHostory {
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .cont{
            width: 180px;
            height: 230px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            img{
                width: 180px;
                height: 180px;
            }
            p{
                width: 100%;
                font-size: 24px;
                font-weight: 400;
                line-height: 30px;
                color: #333333;
                text-align: center;  
            }
            span{
                margin-top: 18px;
                text-align: center;  
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                color: #333333;
            }
        }
    }
    .listBox {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      .item:nth-child(4n+4) {
          margin-right: 0;
      }
      .item {
        margin-right: 8px;
        width: 224px;
        height: 193px;
        margin-bottom: 20px;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .img {
          width: 224px;
          height: 126px;
          img {
            width: 224px;
            height: 126px;
            border-radius: 4px 4px 0px 0px;
          }
        }
        .bot {
          width: 100%;
          height: 67px;
          padding: 0 19px 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .tit {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
          }
          .time {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #999999;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
</style>