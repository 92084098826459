<template>
   <div>
    <commonHeader :current-index="0"></commonHeader>
        <div class="contationBox">
            <div class="contation">
                <div class="left">
                    <tabbar :currentIndex="currentIndex" @tabbarClick="childTabbar"></tabbar>
                </div>
                <div class="right">
                    <hostory v-show="currentIndex === 0"></hostory>
                    <order v-show="currentIndex === 1"></order>
                    <setting v-show="currentIndex === 2"></setting>
                    <vipInforMation  v-show="currentIndex === 3"></vipInforMation>
                    <consumeNumber v-show="currentIndex === 4"></consumeNumber>
                </div>
            </div>
        </div>
    <commonFooter></commonFooter>
   </div>
</template>

<script>
import commonHeader from '@/components/commonHeader'
import tabbar from '@/components/set/leftTabbar'
import hostory from '@/components/set/hostory'
import order from '@/components/set/order'
import consumeNumber from '@/components/set/consumeNumber.vue'
import setting from '@/components/set/setItem'
import vipInforMation from '@/components/set/vipInforMation'
import commonFooter from '@/components/commonFooter'
export default {
components:{
    commonHeader,
    commonFooter,
    tabbar,
    hostory,
    order,
    consumeNumber,
    setting,
    vipInforMation
  },
  data(){
      return{
          currentIndex:0
      }
  },
  created(){
        this.currentIndex = Number(this.$route.query.index); 

  },
  mounted(){
      
  },
  methods:{
      childTabbar(index){
          this.currentIndex  = index;
      }
  }
}
</script>

<style scoped lang="less">
.contationBox{
    width: 100%;
    padding: 20px 0 68px 0;
    background: #F7F7F7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contation{
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left{
            width: 220px;
        }
        .right{
            width:  960px;
            background: #fff;
        }
    }
}
</style>