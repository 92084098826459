<template>
  <div class="box">
    <div class="title"><span>套餐记录</span></div>
    <div class="list">
      <div class="listBox" v-if="arr.length > 0">
          <div class="item" v-for="(item,index) in arr" :key="index">
              <div class="left">
                  <div class="img">
                      <img :src="item.courseImage" alt="">
                      <!-- <span>{{item.packageName}}</span> -->
                  </div>
                  <div class="rightTitle">
                      <div class="topMaxTitle">
                          {{item.courseName}}
                      </div>
                      <div class="centerTt">{{item.packageName}}</div>
                      <div class="botSmallTitle">
                          <span>生效时间：{{item.effectiveTime | toHours(item.effectiveTime)}}</span>
                          <span>失效时间：{{item.invalidTime | toHours(item.invalidTime)}}</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="npHostory" v-else>
          <div class="cont">
              <img src="@/assets/img/noContent.png" alt="" />
              <p>暂无套餐记录!</p>
              <span>快去购买套餐吧!</span>
          </div>
      </div>
      <div class="pp">
        <paying  v-if="arr.length > 0" :rows="rows" :total="total" @clickPage="pageList"></paying>
      </div>
    </div>
  </div>
</template>

<script>
import paying from '@/components/course/paging'
import { getUseLog } from '@/common/public.js'
export default {
  components:{paying},
  data() {
    return {
      page:1,
      rows:10,
      total:'',
      arr:[],
    };
  },
  created(){
      this.getOrderList()
  },
  methods:{
      getOrderList(){
          //获取订单列表
          let data = {
            //   token:this.$store.state.token,
            //   id:this.$store.state.userid,
              page:this.page,
              rows:this.rows
          };
          getUseLog(data).then(res=>{
              this.total = res.result.listSize;
              this.arr = res.result.mapList
          })
      },
        //点击切换下一页、
        pageList(index){
            this.page = index;
            this.getOrderList()
        },
  }
};
</script>

<style scoped lang="less">
.box {
  width: 960px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #007131;
    border-bottom: 1px solid #eeeeee;
    padding: 11px 0;
    span {
      padding: 8px 0;
      border-bottom: 4px solid #fed23e;
    }
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    .listBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        .item{
            width: 920px;
            height: 90px;
            padding: 0 23px 0px 11px; 
            border: 1px solid #EEEEEE;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .left{
                width: 625px;
                height: 68px;
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                align-items: center;
                .img{
                    width: 121px;
                    height: 68px;
                    position: relative;
                    margin-right: 10px;
                    img{
                        width: 121px;
                        height: 68px;
                        background: linear-gradient(42deg, #097638 0%, #FFFFFF 100%);
                    }
                    span{
                        width: 100%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        text-align: center;
                        transform: translate(-50%,-50%);
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 21px;
                        color: #FFFFFF;
                    }
                }
                .rightTitle{
                    height: 68px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .topMaxTitle{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #333333;
                    }
                    .botSmallTitle{
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #999999;
                        span{
                            margin-right: 10px;
                        }
                    }
                }
            }
            .right{
                font-size: 24px;
                font-weight: 400;
                line-height: 33px;
                color: #FF1D00;
            }
        }
    }
    .npHostory {
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .cont{
            width: 180px;
            height: 230px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            img{
                width: 180px;
                height: 180px;
            }
            p{
                width: 100%;
                font-size: 24px;
                font-weight: 400;
                line-height: 30px;
                color: #333333;
                text-align: center;  
            }
            span{
                margin-top: 18px;
                text-align: center;  
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                color: #333333;
            }
        }
    }
    .pp{
        margin: 20px 0;
    }
  }
}
</style>