<template>
    <div class="list">
        <div :class="currentIndex === index ?'active':'item'" v-for="(item,index) in tabbar" :key="index" @click="toChild(index)">
            {{item}}
        </div>
    </div>
</template>

<script>
export default {
    props:{
        currentIndex:{
            type:Number,
            default:0
        }
    },
    data(){
        return{
            tabbar:['浏览记录','我的订单','设置','会员信息','套餐记录'],
        }
    },
    mounted(){
    },
    methods:{
        toChild(index){
            this.$emit('tabbarClick',index)
        }
    }
}
</script>

<style scoped lang="less">
.list{
    width: 220px;
    height: 300px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item{
        padding-left: 20px;
        line-height: 58px;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border-bottom: 1px solid #EEEEEE;
    }
    .active{
        padding-left: 20px;
        line-height: 58px;
        font-size: 14px;
        font-weight: 400;
        color: #007131;
        border-bottom: 1px solid #EEEEEE;
        border-left: 4px solid #097638;
    }
}
</style>