<template>
    <div class="accountBox">
        <div class="item">
            <div class="left">当前登录账号：</div>
            <div class="right">
                <div class="topPhone"><span>{{$store.state.user.phone}}</span><span class="active" @click="mobilePhone">绑定手机号</span></div>
                <div class="binPhone">
                    <span>学习记录当前登录账号为准</span>
                </div>
            </div>
        </div>
        <div class="fiex" v-if="isFlag">
            <div class="title">绑定手机号</div>
            <div class="inputPhone"><input type="text" placeholder="请输入手机号" v-model="userPhone"></div>
            <div class="inputCord"><input class="text" type="text" placeholder="请输入验证码" v-model="getSlat"><div class="selatBtn" @click="getCode">{{count?count+'s':'获取验证码'}}</div></div>
            <div class="inputBtn"><input type="button" value="提交" @click="submit"></div>
        </div>
    </div>
</template>

<script>
import MD5 from '@/common/md5.js'
import { getSalt,sendCode,bindPhone,getUserByPhone } from '@/common/public.js'
export default {
    data(){
        return{
            isFlag:false,
            timer: null,//控制定时器
            saltCode:'',//盐值
            count:'',//倒计时事件
            getSlat:'',//验证码
            userPhone:'',//用户手机号

        }
    },
    methods:{
        //点击提交
        submit(){
            let data = {
                phone:this.userPhone,
                code:this.getSlat,
                userId:this.$store.state.userid,
                token:this.$store.state.token
            };
            //验证手机号
            var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
            let usePhone = new RegExp(reg);
            let muPhone = usePhone.test(this.userPhone);
            if(!this.userPhone){
                this.$message({
                    showClose: true,
                    message: '手机号码不能为空',
                    type: 'error'
                });
            }else if(!muPhone){
                this.$message({
                    showClose: true,
                    message: '请输入正确的手机号',
                    type: 'error'
                });
            }else if(!this.getSlat){
                this.$message({
                    showClose: true,
                    message: '请输入验证码',
                    type: 'error'
                });
            }else{
                bindPhone(data).then(res=>{
                    if(res.code == 200){
                        this.$message({
                            showClose: true,
                            message: '绑定成功',
                            type: 'success'
                        });
                        this.isFlag = false;
                        this.getPhoneUser()
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: 'error'
                        });
                        this.isFlag = false;
                    }
                })
            }
        },
        //根据手机号查询用户的信息
        getPhoneUser(){
            let data = {
                phone:this.userPhone
            };
            getUserByPhone(data).then(res=>{
                this.$store.commit('splitUser',res.result.domain)
            })
        },
        //点击获取验证码
        getCode() {
            this.getSlcode()
        },
        //获取盐值
        getSlcode(){
            getSalt().then(res=>{
                if(res.code == 200){
                this.saltCode = res.result.domain.saltCode;
                this.sendCodeRequest()
                }
            })
        },
        //短信验证码
        sendCodeRequest(){
            let phone = this.userPhone;
            let timestamp = Date.parse(new Date());
            let messageKey = 'TongLiao&&567_NM_yingguoyingyuyuyan%%';
            let saltCode = this.saltCode;
            let sign = MD5.md5(phone+timestamp+messageKey+saltCode)
            let messageType  = 'bind';
            let data = {phone,timestamp,sign,saltCode,messageType}
            //验证手机号
            var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
            let usePhone = new RegExp(reg);
            let muPhone = usePhone.test(this.userPhone);
            if(muPhone){
                sendCode(data).then(res=>{
                    if(res.code == 200){
                        this.timeEnd()
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: 'error'
                        });
                    }
                })
            }else{
                this.$message({
                    showClose: true,
                    message: '请输入正确的手机号',
                    type: 'error'
                });
            }
        },
        //倒计时
        timeEnd(){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.flag = true;
                this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                } else {
                    this.flag = false;
                    clearInterval(this.timer);
                    this.timer = null;
                }
                }, 1000);
            }else{
                console.log(' ') 
            }
        },
        //绑定手机号
        mobilePhone(){
            this.isFlag = true
        },
    }
}
</script>

<style scoped lang="less">
.accountBox{
    width: 100%;
    height: 550px;
    padding: 0 23px;
    display: flex;
    flex-direction: column;
    position: relative;
    .fiex{
        position: absolute;
        top: 157px;
        left: 320px;
        width: 283px;
        height: 250px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 26px 0 30px 0;
        .title{
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: #000000;
        }
        .inputPhone{
            width: 200px;
            height: 32px;
            margin-top: 34px;
            input{
                width: 200px;
                height: 32px;
                background: #FAFAFA;
                border: 1px solid #DFDFDF;
                outline: none;
                padding-left: 5px;
            }
        }
        .inputCord{
            width: 200px;
            height: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            .text{
                width: 116px;
                height: 32px;
                background: #FAFAFA;
                border: 1px solid #DFDFDF;
                outline: none;
                padding-left: 5px;
            }
            .selatBtn{
                cursor: pointer;
                width: 78px;
                height: 32px;
                background: #097638;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                color: #FFFFFF;
            }
        }
        .inputBtn{
            margin-top: 24px;
            input{
                width: 200px;
                height: 32px;
                background: #FF8000;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #FFFFFF;
                outline: none;
                border: none;
                cursor: pointer;
            }
        }
    }
    .item{
        padding: 19px 0;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #eee;
        .left{
            text-align: right;
            width: 100px;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 4px;
        }
        .right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .topPhone{
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #666666;
                .active{
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #007131;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
            .binPhone{
                span{
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                }
                .active{
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #007131;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>