<template>
  <div class="box">
    <div class="titlex"><span>会员信息</span></div>
    <div class="list">
        <div class="packages">
            <div class="titles">套餐剩余次数</div>
            <div class="number"><span>{{userObj.surplusTimes?userObj.surplusTimes:'0'}}</span>/{{userObj.totalTimes?userObj.totalTimes:'0'}}</div>
            <div class="Vipbtn" @click="toUpgrade">升级套餐</div>
        </div>
        <div class="growth">
            <div class="top">会员成长值</div>
            <div class="botChild">
              <div class="userpic">
                <!-- <img src="@/assets/img/logo.png" alt=""> -->
                <img :src="userObj.headPic" alt="">
              </div>
              <div class="vip">{{userObj.gradeName}}</div>
              <div class="numb"><span>{{userObj.gradeValue}}</span>成长值</div>
            </div>
        </div>
        <div class="memberShip">
          <div class="top">会员等级</div>
          <div class="vipCen">
            <div class="item" v-for="(item,index) in vip" :key="index">
              <div class="img">
                <img :src="item.icon" alt="">
              </div>
              <div :class="[activeIndex>=index?'lineActive':'','line']">
                <div :class="['radius',activeIndex>=index?'radiusActives':'']"></div>
              </div>
              <div :class="['titlee',activeIndex>=index?'titleAcc':'']">
                {{item.name}}
              </div>
              <div :class="['num',activeIndex>=index?'numActivv':'']">
                <span class="spanGreent"><span>{{item.minGrowthValue}}</span>~<span>{{item.maxGrowthValue}}</span>积分</span>
              </div>
            </div>
          </div>
          <div class="bot">
            <p>规则</p>
            <p>{{userObj.taskDesc}}</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getUserByPhone,getDataVip } from '@/common/public.js'
export default {
  data() {
    return {
        activeIndex:1,
        userGrade:'',//会员等级
        userObj:{},//保存用户信息
        vip:[],////存储用户的会员等级
    };
  },
  created(){
    this.getUser()
  },
  methods:{
    //查询用户信息
    getUser(){
      let data = {
        phone:this.$store.state.user.phone,
        userId:this.$store.state.userid,
        token:this.$store.state.token
      };
      getUserByPhone(data).then(res=>{
        this.userObj = res.result.domain;
        this.getVip()
      })
    },
    //获取会员等级
    getVip(){
      getDataVip().then(res=>{
        this.vip = res.result.mapList;
        res.result.mapList.map((value,index)=>{
            if(value.name == this.userObj.gradeName){
                this.activeIndex = index;
            }            
        })
      })
    },
    //升级套餐
    toUpgrade(){
      this.$router.push({
        path:'/paymentInfor'
      })
    }
  }
};
</script>
<style>
.el-step__title.is-wait{
  color: #999999;
}
.el-step__description.is-wait{
  color: #999999;
}
.el-step__title.is-process{
  color: #999;
  font-weight: 400;
}
.el-step__description.is-process{
  color: #999;
}
.el-step__title.is-finish{
  color: #333;
}
.el-step__description.is-finish{
  color: #007131;
}
.el-step__head.is-finish{
  color: #FF8000;
    border-color: #FF8000;
}
.el-step__icon {
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 8px;
    font-size: 14px;
    border-radius: 50%;
    background: #fff;
    bottom: 2px;
    box-sizing: border-box;
    transition: .15s ease-out;
}
.el-step__icon-inner {
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    font-weight: 700;
    line-height: 1;
    color: inherit;
    display: none;
}

.el-step__icon.is-text {
    border-radius: 50%;
    border: 2px solid;
    border: 4px solid #999;
}
.el-step__head.is-finish .el-step__icon.is-text {
    border-radius: 50%;
    border: 2px solid;
    border: 4px solid #FF8000 !important;
}
</style>
<style scoped lang="less">
.box {
  width: 960px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .titlex {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #007131;
    border-bottom: 1px solid #eeeeee;
    padding: 11px 0;
    span {
      padding: 8px 0;
      border-bottom: 4px solid #fed23e;
    }
  }
  .list {
    width: 100%;
    padding: 0 23px;
    display: flex;
    flex-direction: column;
    .packages{
        padding: 30px 0 50px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #eee;
        .titles{
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            color: #333333;
            margin-right: 23px;
        }
        .number{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
            margin-right: 19px;
            span{
                font-size: 24px;
                color: #ff8000;
            }
        }
        .Vipbtn{
            width: 64px;
            height: 24px;
            background: #FF8000;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    .growth{
        padding: 27px 0 50px 0;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .top{
          font-size: 18px;
          font-weight: 400;
          line-height: 25px;
          color: #333333;
        }
        .botChild{
          width: 220px;
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .userpic{
            width: 36px;
            height: 36px;
            img{
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }
          }
          .vip{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
          }
          .numb{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
              font-size: 24px;
              font-weight: 400;
              line-height: 33px;
              color: #FF8000;
              margin-right: 6px;
            }
          }
        }
    }
    .memberShip{
      padding: 20px 0 36px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #333333;
      }
      .vipCen::-webkit-scrollbar-track{
        background: rgba(0,0,0,.1);
        border-radius: 0;
      }
      .vipCen::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
      }
      .vipCen::-webkit-scrollbar-thumb{
          cursor: pointer;
          border-radius: 5px;
          background: #007131;
          transition: color .2s ease;
      }
      .vipCen{
        width: 800px;
        padding:10px 0 10px 85px;
        overflow-x: auto;
        white-space: nowrap;
        .item:nth-of-type(1){
            width: 80px;
            .radius{
              left: 46% !important;
            }
        }
        .item:last-child{
          width: 80px;
          .radius{
            left: 46% !important;
          }
        }
        .item{
          width: 300px;
          display: inline-block;
          .img{
            width: 100%;
            height: 24px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img{
              width: 22px;
              height: 24px;
            }
          }
          .line{
            width: 100%;
            height: 2px;
            margin-top: 8px;
            background: #eee;
            position: relative;
            .radius{
              width: 8px;
              height: 8px;
              position: absolute;
              background: #eee;
              border-radius: 50%;
              left: 49%;
              top: -4px;
            }
            .radiusActives{
              background: #FF8000;
            }
          }
          .lineActive{
            background: #FF8000;
          }
          .titlee{
            width: 100%;
            text-align: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #999999;
            margin-top: 14px;
          }
          .titleAcc{
            color: #333333;
          }
          .num{
            width: 100%;
            font-size: 12px;
            margin-top: 5px;
            font-weight: 400;
            line-height: 17px;
            text-align: center;
            color: #999999;
          }
          .numActivv{
            color: #007131;
          }
        }
        
      }
      .bot{
        width: 100%;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: #666666;
        padding-left: 85px;
      }
    }
  }
}
</style>