<template>
  <div class="boxSeet">
    <div class="title">
        <span :class="spanIndex === index?'active':''" v-for="(item,index) in spanArr" :key="index" @click="itemChild(index)">{{item}}</span>
    </div>
    <div class="list">
        <dataSetting v-show="spanIndex === 0"></dataSetting>
        <accountSetting v-show="spanIndex === 1"></accountSetting>
        <passWordSetting v-show="spanIndex === 2"></passWordSetting>
    </div>
  </div>
</template>

<script>
import dataSetting from './dataSetting'
import accountSetting from './accountSetting'
import passWordSetting from './passWordSetting'
export default {
    components:{ 
        dataSetting,
        accountSetting,
        passWordSetting 
    },
  data() {
    return {
        arr:[1,2,3,4,5,6,7,8,9,12,13,11,14,15,16,17],
        //   arr: [],
        spanIndex:0,
        spanArr:['资料设置','账号设置','密码设置']
    };
  },
  created(){
    if(this.$store.state.user.isPassword == 0){
      this.spanArr = ['资料设置','账号设置','密码设置'];
    }else{
      this.spanArr = ['资料设置','账号设置'];
    }
  },
  methods:{
      itemChild(index){
          this.spanIndex = index;
      }
  }
};
</script>

<style scoped lang="less">
.boxSeet {
  width: 960px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #666;
    border-bottom: 1px solid #eeeeee;
    padding: 11px 0;
    span {
      padding: 8px 0;
      margin-right: 40px;
      
    }
    .active{
        color: #007131;
        border-bottom: 4px solid #fed23e;
    }
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
}
</style>