<template>
    <div class="passBox">
        <div class="title">设置信息</div>
        <div class="input">
            <span>输入密码</span> <input type="password" v-model="passInput">
        </div>
        <div class="input">
            <span>确认密码</span> <input type="password" v-model="conInput">
        </div>
        <div class="btnPass">
            <div class="preservation" @click="preservation">
                保存
            </div>
        </div>
    </div>
</template>

<script>
import { setPassword,getUserByPhone } from '@/common/public.js'
export default {
    data(){
        return{
            passInput:'',
            conInput:''
        }
    },
    methods:{
        //点击保存按钮
        preservation(){
            let data = {
                password:this.passInput,
                repeatPassword:this.conInput,
                userId:this.$store.state.userid,
                token:this.$store.state.token
            };
            if(!this.passInput){
                this.$message({
                    showClose: true,
                    message: '请输入密码',
                    type: 'error'
                });
            }else if(!this.conInput){
                this.$message({
                    showClose: true,
                    message: '请确认密码',
                    type: 'error'
                });
            }else if(this.passInput !== this.conInput){
                this.$message({
                    showClose: true,
                    message: '您输入的密码不一致',
                    type: 'error'
                });
            }else{
                setPassword(data).then(res=>{
                    if(res.code == 200){
                        this.getPhoneUserInfor()
                        this.$message({
                            showClose: true,
                            message: '设置密码成功',
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: 'error'
                        });
                    }
                })
            }
            
        },
        //根据手机号查询用户信息
        getPhoneUserInfor(){
            let data = {
               userId:this.$store.state.userid,
               token:this.$store.state.token,
               phone:this.$store.state.user.phone
            };
            getUserByPhone(data).then(res=>{
                this.$store.commit('splitUser',res.result.domain)
            })
        }
    }
}
</script>

<style scoped lang="less">
.passBox{
    width: 100%;
    height: 550px;
    .title{
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #333333;
        margin: 13px 0;
        padding-left: 4px;
    }
    .input{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 13px 0;
        padding-left: 8px;
        span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            margin-right: 10px;
        }
        input{
            width: 160px;
            height: 32px;
            background: #FAFAFA;
            border: 1px solid #DFDFDF;
            margin-right: 10px;
            padding-left: 5px;
        }
    }
    .btnPass{
        padding-left: 65px;
        margin: 16px 0;
        .preservation{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 44px;
            background: #FF8000;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
</style>